<template>
    <div>
        <span v-html="text"></span>
    </div>
</template>

<script>

export default {

    name: "PromoterText",

    props: {
        text: String
    }

}

</script>

<style scoped>

</style>