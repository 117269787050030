<template>

    <div id="oc-products"
         class="owl-carousel products-carousel carousel-widget"
         data-loop="false"
         data-pagi="false"
         data-autoplay="3000"
    >
        <div v-for="image in promoter.images_urls" :key="image.id">

            <div class="oc-item">
                <div class="product">
                    <div class="product-image">
                        <a href="#"><img :src="rescaledImage(image)" :alt="image.id"></a>
                        <div class="bg-overlay">
                            <div class="bg-overlay-content align-items-end justify-content-between" data-hover-animate="fadeIn" data-hover-speed="400">
                                <a :href="fullImage(image)"
                                   class="overlay-trigger-icon bg-dark text-light"
                                   data-hover-animate="fadeInDownSmall"
                                   data-hover-animate-out="fadeOutUpSmall"
                                   data-hover-speed="350"
                                   data-lightbox="image"><i class="icon icon-line-zoom-in"></i>
                                </a>
                            </div>
                            <div class="bg-overlay-bg bg-transparent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PromoterImagesCarousel",

    props: {
        promoter: Object,
    },

    methods: {
        rescaledImage(image) {
            return _.get(image, 'rescaled')
        },
        fullImage(image) {
            return _.get(image, 'full')
        },
    }
}
</script>

<style scoped>

</style>