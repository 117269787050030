require('./frontbootstrap');

import Vue from 'vue'
import {i18n} from "./vue-i18n";
import {BootstrapVue} from 'bootstrap-vue'

Vue.use(BootstrapVue)

Vue.component('translatable-item-table', require('@/Shared/Front/TranslatableItemTable').default);
Vue.component('new-products-carousel', require('@/Shared/Front/NewProductsCarousel').default);
Vue.component('related-products-carousel', require('@/Shared/Front/RelatedProductsCarousel').default);
Vue.component('promoter-images-carousel', require('@/Shared/Front/PromoterImagesCarousel').default);
Vue.component('product-showcase', require('@/Shared/Front/ProductShowcase').default);
Vue.component('product-index', require('@/Shared/Front/ProductIndex').default);
Vue.component('product-new-arrival-index', require('@/Shared/Front/ProductNewArrivalIndex').default);
Vue.component('product-professional-index', require('@/Shared/Front/ProductProfessionalIndex').default);
Vue.component('product-item-index', require('@/Shared/Front/ProductItemIndex').default);
Vue.component('promoter-text', require('@/Pages/Front/PromoterText').default);
Vue.component('contact-form', require('@/Pages/Front/ContactForm').default);
Vue.component('product-finder', require('@/Pages/Front/ProductFinder').default);
Vue.component('distribution', require('@/Pages/Front/Distribution').default);

window.Vue = require('vue');

const app = new Vue({
    el: '#app',
    i18n
});
