<template>
    <div>
        <div v-show="totalRows" v-html="summary"></div>

        <b-table
            v-bind="$attrs"
            v-on="$listeners"
            :items="items"
            :per-page="localPerPage"
            :current-page="localCurrentPage"
            :empty-text="$attrs['empty-text'] || 'Nessun elemento da visualizzare'"
            :empty-filtered-text="$attrs['empty-filtered-text'] || 'Nessun elemento soddisfa i criteri di ricerca'"
            @filtered="onFiltered"
            :head-variant="_.get($attrs, 'head-variant', 'dark')"
            :outlined="_.get($attrs, 'outlined', true)"
            :bordered="_.get($attrs, 'bordered', false)"
            :striped="_.get($attrs, 'striped', true)"
            :hover="_.get($attrs, 'hover', true)"
            responsive
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </b-table>

        <b-pagination
            v-if="totalRows > localPerPage"
            :total-rows="totalRows"
            :per-page="localPerPage"
            v-model="localCurrentPage"
        />
    </div>
</template>

<script>
export default {

    name: "ItemsTable",

    inheritAttrs: false,

    props: {
        items: {
            type: Array,
            // default: () => []
        },
        perPage: {
            type: Number,
            default: 15
        },
        currentPage: {
            type: Number,
            default: 1
        },
    },

    data() {
        return {
            fromRow: 0,
            toRow: 0,
            localPerPage: this.perPage,
            localCurrentPage: this.currentPage,
            totalRows: 0
        }
    },

    watch: {
        currentPage(newVal) {
            this.localCurrentPage = newVal
        },
        localCurrentPage(newVal) {
            this.$emit('update:current-page', newVal)
            this.refreshCounter()
        },
        totalRows() {
            this.refreshCounter()
        },
        localCurrentPerPage() {
            this.refreshCounter()
        },
        'items.length': {
            handler() {
                this.totalRows = this.items.length
                this.refreshCounter()
            },
            immediate: true
        }
    },

    computed: {
        _() {
            return _
        },
        summary() {
            return this.$t('pagination-info', {from: this.fromRow, to: this.toRow, total: this.totalRows})
            // return `Visualizzo da ${this.fromRow} a ${this.toRow} elementi di ${this.totalRows}`
        },
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.localCurrentPage = 1
        },
        refreshCounter() {
            if (this.localPerPage === 0) {
                return
            }
            let from = this.totalRows ? 1 : 0
            from = from + (this.localCurrentPage - 1) * this.localPerPage
            let to = Math.min(from + this.localPerPage -1, this.totalRows)
            this.fromRow = from
            this.toRow = to
        },
    }
}
</script>
