<template>
    <div class="container clearfix">

        <div id="shop" class="shop row gutter-30" data-layout="fitRows">

            <div v-for="product in localProducts" :key="product.id" class="product col-sm-6 col-md-4 col-lg-3 col-12">

                    <div class="grid-inner">

                        <div class="product-image">
                            <a href="#"><img :src="rescaledImage(product)" :alt="product.fullname"></a>
                            <div v-if="product.is_new" class="sale-flash badge badge-danger p-2">Novità!</div>
                            <div class="bg-overlay">
                                <div class="bg-overlay-content align-items-end justify-content-between">
                                    <a :href="'/products/'  + product.id" class="btn btn-dark mr-2"><i class="icon-line-open"></i></a>
                                </div>
                                <div class="bg-overlay-bg bg-transparent"></div>
                            </div>
                        </div>
                        <div class="product-desc center">
                            <h3 class="text-trabaldo" style="margin-bottom: 0px">{{ product.name }}</h3>
                        </div>
                    </div>

            </div>

        </div>

        <b-pagination
            v-if="totalRows > perPage"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
        />
    </div>

</template>

<script>
export default {

    name: "ProductShowcase",

    props: {
        products:[Array, Object],
        finders:[Array, Object],
    },

    data() {
        return {
            localProducts: [],
            totalRows: 0,
            perPage: 0,
            currentPage: 1,
        }
    },

    created() {
        this.fetchData()
    },

    methods: {
        mainImage(product) {
            return _.find(product.images_urls, 'is_main')
        },

        rescaledImage(product) {
            return _.get(this.mainImage(product), 'rescaled')
        },

        fetchData() {
            if (_.isEmpty(this.finders)) {
                axios.get('/product-finder', {
                    params: {
                        'page': this.currentPage,
                    }}).then(({data}) => {
                    this.localProducts = data.data
                    this.totalRows = data.meta.total
                    this.perPage = data.meta.per_page
                })
            } else {
                axios.get('/product-finder', {
                    params: {
                        'finders': this.finders,
                        'page': this.currentPage,
                    }}).then(({data}) => {
                    this.localProducts = data.data
                    this.totalRows = data.meta.total
                    this.perPage = data.meta.per_page
                })
            }
        }
    },

    watch: {
        currentPage() {
            this.fetchData()
        },
        products(newVal) {
            this.localProducts = newVal.data
            this.totalRows = newVal.meta.total
            this.perPage = newVal.meta.per_page
            this.currentPage = 1
        },
    },
}
</script>

<style scoped>

</style>