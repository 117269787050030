<script>
export default {
    name: "ProductFinder",

    props: {
        productsStart: Array,
        finders: Array,
        struct: Array,
        s_items: Array,
        s_categories: Array,
    },

    data() {
        return {
            localFinders: {...this.finders},
            products: {...this.productsStart},
            selectFinders: [],
            currentCategory: 1,
            chain: [],
         }
    },

    computed: {
        findersClass() {
            let counters = !_.isUndefined(this.localFinders) ? _.size(this.localFinders) : 0
            return  counters <= 2 ? 'col-lg-6 col-md-6' : 'col-lg-4 col-md-4'
        },
        breadcrumbs() {
            let b = [];
            _.each(this.chain, (c) => {
                b.push({
                    text: _.find(this.s_items, ['id', c.item]).name,
                })
            })
            return b
        },

        choice() {
            if(this.currentCategory){
                return _.find(this.s_categories, ['id', this.currentCategory]).text
            }
            else {
                return null
            }
        }
    },

    methods: {
        clicked(finder) {
            this.chain.push({category: this.currentCategory, item: finder.id})
        },
        filter() {
            this.selectFinders = _.map(this.chain, 'item')
            axios.get('/product-finder', {
                params: {
                    finders: this.selectFinders
                }
            }).then(({data}) => {
                this.products = data
            })
            this.currentCategory = this.getNextCategory()
            this.localFinders = _.filter(this.s_items, ['search_category_id', this.currentCategory])
        },

        getNextCategory() {
            let finderChain = _.map(this.chain, 'item')
            if (finderChain.length == 0){
                return 1
            }
            if (finderChain.length == 1){
                return 2
            }
            if (finderChain.length == 2){
                return _(this.struct)
                    .filter(['parent_search_item_id', finderChain[0]])
                    .filter(['search_item_id', finderChain[1]]).value()[0].search_category_id
            }
            else {
                return null
            }
        },

        backTo(index) {
            this.chain.splice(index)
        },

        getDescription(finderId) {
            return _.find(this.s_items, ['id', finderId]).description
        }
    },

    watch: {
        chain() {
            this.filter()
        }
    }
}
</script>

<style scoped>

    .team-image:hover {
        opacity: 1;
    }

    .team-image:not(:hover) {
        opacity: 0.6;
    }

</style>