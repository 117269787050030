<template>

    <div id="oc-products"
         class="owl-carousel products-carousel carousel-widget"
         data-pagi="false"
         data-items-xs="1"
         data-items-sm="2"
         data-items-md="3"
         data-items-lg="4"
         data-autoplay="3000"
    >
        <div v-for="product in products" :key="product.id">

            <div class="oc-item">
                <div class="product">
                    <div class="product-image">
                        <a href="#"><img :src="rescaledImage(product)" :alt="product.name"></a>
                        <div class="bg-overlay">
                            <div class="bg-overlay-content align-items-end justify-content-between" data-hover-animate="fadeIn" data-hover-speed="400">
                                <a :href="'/products/'  + product.id" class="btn btn-dark mr-2"><i class="icon-line-open"></i></a>
                            </div>
                            <div class="bg-overlay-bg bg-transparent"></div>
                        </div>
                    </div>
                    <div class="product-desc center">
                        <h3 class="text-trabaldo" style="margin-bottom: 0px">{{ product.name }}</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "RelatedProductsCarousel",

    props: {
        products: Array,
    },

    methods: {
        mainImage(product) {
           return _.find(product.images_urls, 'is_main')
        },

        rescaledImage(product) {
            return _.get(this.mainImage(product), 'rescaled')
        }
    }
}
</script>

<style scoped>

</style>