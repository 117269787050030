<script>
import L from 'leaflet';
import {LControl, LMap, LMarker, LPopup, LTileLayer} from 'vue2-leaflet';

export default {
    name: "Distribution",

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LControl
    },

    props: {
        distributions: Array,
    },

    data() {
        return {
            filter: {
                region: null,
                province: null,
                abroad: null
            },
            radioOptions: [
                { text: this.$t('filter-all'), value: null },
                { text: this.$t('filter-italy'), value: false },
                { text: this.$t('filter-abroad'), value: true },
            ],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 4,
            center: [45.7134505 ,8.2802783],
            homeCoordinates: [45.7134505, 8.2802783],
            homeMarker: L.icon({
                iconUrl: '/images/map-markers/marker-home.png',
                iconSize: [40, 52],
                iconAnchor: [20, 52]
            }),
            unselected: L.icon({
                iconUrl: '/images/map-markers/marker-trabaldo.svg',
                iconSize: [30, 30],
                iconAnchor: [15,30]
            }),
            selected: L.icon({
                iconUrl: '/images/map-markers/marker-selected.svg',
                iconSize: [36, 36],
                iconAnchor: [18,36]
            }),
            markers: {...this.distributions},
            filteredItems: [],
            fields: [
                {
                    key: 'name',
                    label: this.$t('table-header-name'),
                    sortable: true
                },
                {
                    key: 'address',
                    label: this.$t('table-header-address')
                },
                {
                    key: 'city',
                    label: this.$t('table-header-city')
                },
                {
                    key: 'postcode',
                    label: this.$t('table-header-postcode')
                },
                {
                    key: 'province',
                    label: this.$t('table-header-province'),
                    sortable: true
                },
                {
                    key: 'region',
                    label: this.$t('table-header-region'),
                    sortable: true
                },
                {
                    key: 'phone',
                    label: this.$t('table-header-phone'),
                    sortable: true
                },
                {
                    key: 'checkbox',
                    label: this.$t('table-header-zoommap'),
                    tdClass: 'align-middle text-center'
                },
            ],
        }
    },

    computed: {
        regionsInCountry() {
            if(this.filter.abroad != null) {
                let inCountry = _.filter(this.distributions, ['abroad', this.filter.abroad])
                return _.map(_.uniqBy(inCountry, 'region'), item => { return item.region });
            }
            else {
                return _.map(_.uniqBy(this.distributions, 'region'), item => { return item.region });
            }
        },

        provincesInRegion() {
            if(this.filter.region) {
                let inRegion = _.filter(this.distributions, ['region', this.filter.region])
                return _.map(_.uniqBy(inRegion, 'province'), item => { return item.province });
            }
        }
    },

    methods: {
        markerColor(marker) {
            if (this.filter.abroad == null && this.filter.region == null && this.filter.province == null) {
                return this.unselected
            }
            else {
                return _.find(this.filteredItems, ['id', marker.id]) ? this.selected : this.unselected
            }
        },

        markerCoordinates(distribution) {
            return [distribution.latitude, distribution.longitude]
        },

        filtered(filteredItems) {
            this.filteredItems = filteredItems
            this.adjustZoomAndCenter()
        },

        filterTable: function(tableRow, filter){
            let filterVal = true
            if (filter.abroad != null && filterVal)
            {
                filterVal = tableRow.abroad == filter.abroad
                if (filter.region && filterVal) {
                    filterVal = tableRow.region == filter.region
                    if (filter.province) {
                        filterVal = tableRow.province == filter.province
                    }
                }
            }

            return  filterVal
        },

        centerOnHome() {
            this.center = this.homeCoordinates
            this.zoom = 9
        },

        centerSelected(id) {
            const centered = _.find(this.distributions, ['id', id])
            this.center = this.markerCoordinates(centered)
            this.zoom = 11
            this.scrollToMap()
        },

        resetZoom() {
            this.center = this.homeCoordinates
            this.zoom = 4
        },

        adjustZoomAndCenter() {
            if (this.filter.abroad) {
                this.center = this.homeCoordinates
                this.zoom = 5
                if (this.filter.region) {
                    this.center = this.markerCoordinates(this.filteredItems[0])
                }
            }
            if (this.filter.abroad == false) {
                this.center = [41.9, 12.5]
                this.zoom = 5
                if (this.filter.region) {
                    this.center = this.markerCoordinates(this.filteredItems[0])
                    this.zoom = 7
                    if (this.filter.province) {
                        this.center = this.markerCoordinates(this.filteredItems[0])
                        this.zoom = 9
                    }
                }
            }
            if (this.filter.abroad == null) {
                this.center = this.homeCoordinates
                this.zoom = 4
            }

        },

        scrollToMap() {
            const el = this.$el.getElementsByClassName('map-wrapper')[0];

            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        },
    },

    watch: {
        'filter.abroad'() {
            this.filter.region = null
        },

        'filter.region'() {
            this.filter.province = null
        },

        'highlighted'() {
            this.centerSelected()
        }
    }

}
</script>

<style scoped>

</style>