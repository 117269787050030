import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import Locale from './vue-i18n-locales.generated';
import Locale from './locales';

Vue.use(VueI18n);

const lang = document.documentElement.lang.substr(0, 2);

const i18n = new VueI18n({
    locale: lang,
    silentTranslationWarn: true,
    messages: Locale,
});

export { i18n };
