<template>
    <div class="col-lg-6">

        <div class="fancy-title title-border">
            <h3>{{ $t('contacts-contact-us') }}</h3>
        </div>

        <div class="form-widget">

            <b-form @submit="submit">
                <b-row>
                    <b-col cols="6">
                        <b-form-group
                            id="name"
                            :label="$t('contacts-name')"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            id="email"
                            :label="$t('contacts-email')"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="form.email"
                                type="email"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-form-group
                    id="subject"
                    :label="$t('contacts-subject')"
                    label-for="subject"
                >
                    <b-form-input
                        id="subject"
                        v-model="form.subject"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="text"
                    :label="$t('contacts-message')"
                    label-for="text"
                >
                    <b-form-textarea
                        id="text"
                        v-model="form.text"
                        required
                        rows="5"
                    ></b-form-textarea>
                </b-form-group>

                <b-button type="submit" variant="primary">{{ $t('contacts-send') }}</b-button>
            </b-form>

        </div>

    </div>

</template>

<script>

export default {

    name: "ContactForm",

    data() {
        return {
            form: {
                name: null,
                email: null,
                subject: null,
                text: null
            }
        }
    },

    methods: {
        submit() {
            return axios.post('/contacts', this.form).then(data => {
                if (data.status === 200) {
                    this.form = {
                        name: null,
                        email: null,
                        subject: null,
                        text: null
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>